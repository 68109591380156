import Base from "./Base";

/**
 * String storage object class.
 */
export default class String extends Base<string> {
  /**
   * Gets value from storage.
   * @returns Promise with value from storage.
   * @throws NotSet If was not set.
   */
  public async get(): Promise<string> {
    return this.baseGet();
  }

  /**
   * Sets to storage.
   * @param value Set this value.
   * @returns Promise.
   */
  public set(value: string): Promise<void> {
    return this.baseSet(value);
  }
}
