// External.
// For search element.
import React from "react";
// For text translations.
import i18n from "i18n-js";

import NumberColumn from "./NumberColumn";

/**
 * MeterAsKMColumn column, extends Number.
 */
export default class MeterAsKMColumn extends NumberColumn {
  /**
   * Creates an instance of Column.
   * @param name Name of the API-field.
   * @param [title] Title for column.
   * @param [has_search] Column has search (Default: true).
   */
  public constructor({
    name,
    title,
    has_search,
    precision,
  }: {
    name: string;
    title?: string;
    has_search?: boolean;
    precision?: number;
  }) {
    super({
      name,
      title,
      has_search,
      precision: precision || 1,
    });
  }
  _strip_insignificant_zeros = true;

  /**
   * Modifies and returns row value if it's set
   * @param row table row
   * @returns JSX.Element | string | null element
   */
  public rowValue(row: { [key: string]: any }): JSX.Element | string | null {
    const value = row[this.name()] || null;
    if (value)
      return (
        <>
          {i18n.toNumber(value / 1000, {
            delimiter: this._delimeter,
            separator: this._separator,
            precision: this._precision,
            strip_insignificant_zeros: this._strip_insignificant_zeros,
          })}
          &nbsp;KM
        </>
      );

    return null;
  }
}
