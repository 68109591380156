// External.
// For search element.
import React from "react";
// For search element text translations.
import i18n from "i18n-js";

// Internal.
// Parent.
import Column from "./Column";
// Component for picking date range.
import DateRangePicker from "../../components/DateRangePicker";

/**
 * Date column.
 */
export default class DateColumn extends Column {
  // https://github.com/fnando/i18n-js#date-formatting
  DATE_FORMAT = "%-d.%-m.%Y";
  /**
   * Search element.
   * @param search Current search values.
   * @param setSearchValue Function to set search value.
   * @returns Search element.
   */
  public searchElement(
    search: { [key: string]: any },
    setSearchValue: (key: string, value: any) => void
  ): JSX.Element | null {
    if (!this.hasSearch()) return null;
    const searchNameFrom: string = this.searchName() + "__gte";
    const searchNameTo: string = this.searchName() + "__lte";
    return (
      <DateRangePicker
        onSelected={(fromDate: Date, toDate: Date) => {
          setSearchValue(searchNameFrom, fromDate);
          setSearchValue(searchNameTo, toDate);
        }}
        buttonText={i18n.t("Dates")}
        startDate={
          search[searchNameFrom] ? new Date(search[searchNameFrom]) : undefined
        }
        endDate={
          search[searchNameTo] ? new Date(search[searchNameTo]) : undefined
        }
      />
    );
  }

  /**
   * Modifies and returns row value if it's set
   * @param row table row
   * @returns JSX.Element | string | null element
   */
  public rowValue(row: { [key: string]: any }): JSX.Element | string | null {
    const value = row[this.name()] || null;
    if (value) return i18n.strftime(new Date(value), this.DATE_FORMAT);

    return null;
  }
}
