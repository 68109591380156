// External imports.
// React, React element, useState (set/get state) and useEffect (detect state changes).
import React, { ReactElement, useState, useEffect } from "react";
// Translation.
import i18n from "i18n-js";
// View-component from React Native.
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
// DataTable- and Button-components from React Native Paper.
import { DataTable, Button } from "react-native-paper";

// Internal imports.
// Messager for sending messages to user.
import Messager from "../classes/Messager";
// REST object to handle API-calls.
import REST from "../classes/REST";
// Search storage container.
import Search from "../classes/Storage/Search";
import { NotSet } from "../classes/Storage/Base";

// Column-class.
import Column from "../classes/Columns/Column";
// Loading-component.
import Loading from "./Loading";
import { useCallback } from "react";
import { HTTP400Error } from "../classes/HTTPErrors";

/**
 * Table with API-connection.
 * @param { mRest, endpoint, columns, search, setSearch, mMessager }
 * @returns React element.
 */
const Table: React.FC<{
  mRest: REST;
  endpoint: string;
  columns: Column[];
  mMessager: Messager;
  getRowStyle?: (row: { [key: string]: any }) => null | StyleProp<ViewStyle>;
}> = ({
  mRest,
  endpoint,
  columns,
  mMessager,
  getRowStyle,
}: {
  mRest: REST;
  endpoint: string;
  columns: Column[];
  mMessager: Messager;
  getRowStyle?: (row: { [key: string]: any }) => null | StyleProp<ViewStyle>;
}): ReactElement => {
  const [search, setSearch] = useState<{ [key: string]: any } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<{ [key: string]: any }[] | null>(null);
  const [currPage, setCurrPage] = useState<number | null>(null);
  const [pages, setPages] = useState<number>(0);

  /**
   * Set search value.
   * @param key Key of the search value.
   * @param value Search value.
   */
  const setSearchValue = (key: string, value: any): void => {
    setSearch((search: { [key: string]: any }) => ({
      ...search,
      [key]: value,
    }));
  };

  /**
   * Convert HTTP400Error to column errors
   * @param error
   * @returns
   */
  const handleError = (error: any) => {
    if (error instanceof HTTP400Error) {
      let errorMessages: string[] = [];
      for (let column of columns) {
        let errors = error.dataValStrArr(column.name());
        if (errors) {
          errorMessages.push(column.title() + ": " + errors.join(", "));
        }
      }
      if (errorMessages.length > 0) {
        error.message = errorMessages.join("\n");
      }
    }
    return error;
  };

  /**
   * Get data from API.
   * @param page Page number to get.
   */
  const getData = (page: number) => {
    if (search === null) return;
    setLoading(true);
    const tmpSearch = Object.assign({}, search);
    tmpSearch.page = page;
    mRest
      .report(endpoint, tmpSearch)
      .then(
        ({
          results,
          page,
          pages,
        }: {
          results: { [key: string]: any }[];
          page: number;
          pages: number;
        }) => {
          setCurrPage(page - 1);
          setPages(pages);
          setRows(results);
          mSearch.set(search);
          setLoading(false);
        }
      )
      .catch((error: any) => {
        setCurrPage(null);
        setRows(null);
        setLoading(false);
        mMessager.handleError(handleError(error));
      });
  };

  /**
   * Transform given UI page number to API page number.
   * @param uiPage Ui page number to transform to page number.
   * @returns Given UI page number as API page number.
   */
  const uiPageToPage = (uiPage: number | null) => {
    return uiPage !== null && uiPage >= 0 ? uiPage + 1 : 1;
  };
  const mSearch = new Search("table_" + endpoint);
  if (search === null && !loading) {
    setLoading(true);
    mSearch
      .get()
      .then((storageSearch): void => {
        setSearch(storageSearch);
      })
      .catch((error: any) => {
        setSearch({});
        if (!(error instanceof NotSet)) {
          mMessager.handleError(error);
        }
      });
  }

  /**
   * Download XLSX-file.
   */
  const downloadXLSX = () => {
    if (search === null) return;
    setLoading(true);
    mRest
      .downloadXLSX(endpoint, i18n.t("report"), search)
      .then((path: string) => {
        setLoading(false);
        mMessager.message(i18n.t("Finished downloading to ") + path);
      })
      .catch((error: any) => {
        setLoading(false);
        mMessager.handleError(handleError(error));
      });
  };

  const _getRowStyle = useCallback(
    (row) => {
      if (typeof getRowStyle === "function") return getRowStyle(row);
      return null;
    },
    [getRowStyle]
  );

  // Get data when search changes.
  useEffect(() => {
    if (search !== null) getData(uiPageToPage(currPage));
  }, [search]);

  return (
    <View style={styles.tableView}>
      {search && Object.keys(search).length > 0 && (
        <Button mode="contained" onPress={() => setSearch({})}>
          {i18n.t("Clear search")}
        </Button>
      )}
      <DataTable>
        <DataTable.Header>
          {columns.map((column: Column, i: number) => {
            return <DataTable.Title key={i}>{column.title()}</DataTable.Title>;
          })}
        </DataTable.Header>
        <DataTable.Row>
          {columns.map((column: Column, i: number) => {
            return (
              <DataTable.Cell key={i}>
                {column.searchElement(search || {}, setSearchValue)}
              </DataTable.Cell>
            );
          })}
        </DataTable.Row>
        {loading ? (
          <Loading />
        ) : (
          rows &&
          rows.map((row: { [key: string]: any }, i: number) => {
            return (
              <DataTable.Row key={i} style={_getRowStyle(row)}>
                {columns.map((column: Column, i: number) => {
                  return (
                    <DataTable.Cell key={i}>
                      {column.rowValue(row)}
                    </DataTable.Cell>
                  );
                })}
              </DataTable.Row>
            );
          })
        )}
        {!loading && currPage !== null && pages && (
          <DataTable.Pagination
            page={currPage}
            numberOfPages={pages}
            onPageChange={(page) => {
              getData(uiPageToPage(page));
            }}
            label={currPage + 1 + "/" + pages}
          />
        )}
      </DataTable>
      <Button onPress={downloadXLSX}>XLSX</Button>
    </View>
  );
};

// Styles for this component.
const styles = StyleSheet.create({
  tableView: {
    backgroundColor: "white",
  },
});

export default Table;
