// External imports.
// React, React element and useState (set/get state).
import React, { ReactElement, useState } from "react";
// TextInput- and View-components from React Native.
import { StyleSheet, ImageBackground } from "react-native";

// Internal imports.
// REST object to handle API-calls.
import REST from "../classes/REST";
// Messager for sending messages to user.
import Messager from "../classes/Messager";

// App's theme.
import { theme } from "../core/theme";
import LoginView from "../components/views/LoginView";
import ForgotPasswordView from "../components/views/ForgotPasswordView";
import { ASSETS_DIR_NAME } from "../consts";

/**
 * Login screen.
 * @param {
 *   mRest,
 *   mMessager,
 * }
 * @returns React element.
 */
const LoginScreen: React.FC<{
  mRest: REST;
  mMessager: Messager;
  baseUrl: string;
}> = ({
  mRest,
  mMessager,
  baseUrl,
}: {
  mRest: REST;
  mMessager: Messager;
  baseUrl: string;
}): ReactElement => {
  // Loading status.
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  return (
    <ImageBackground
      source={require("../../assets/" + ASSETS_DIR_NAME + "/background.jpg")}
      resizeMode="cover"
      style={styles.background}
    >
      {forgotPassword ? (
        <ForgotPasswordView
          mRest={mRest}
          mMessager={mMessager}
          setForgotPassword={setForgotPassword}
          baseUrl={baseUrl}
        />
      ) : (
        <LoginView
          mRest={mRest}
          mMessager={mMessager}
          setForgotPassword={setForgotPassword}
        />
      )}
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: "100%",
    backgroundColor: theme.colors.surface,
  },
});

export default LoginScreen;
