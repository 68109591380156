// External imports.
// AsyncStorage class.
import AsyncStorage from "@react-native-async-storage/async-storage";

/**
 * Error for telling that storage item was not set.
 */
export class NotSet extends Error {
  /**
   * Creates an instance of not set.
   */
  constructor() {
    super("Storage item not set!");
    this.name = "NotSet";
  }
}

/**
 * Abstracted base storage class.
 * @template Type Type of stored value.
 */
export default abstract class Base<Type> {
  /**
   * Name of storage item.
   */
  private _name: string = "";

  /**
   * Gets name.
   */
  protected get name(): string {
    return this._name;
  }

  /**
   * Value of storage item.
   */
  private value: string | null = null;

  /**
   * Creates an instance of storage.
   * @param name Name of the storage.
   */
  public constructor(name: string) {
    this._name = name;
  }

  /**
   * Gets value from storage.
   * @returns Promise with value from storage.
   * @throws NotSet If was not set.
   */
  protected async baseGet(): Promise<string> {
    if (!this.value) {
      this.value = await AsyncStorage.getItem(this.name);
    }
    if (!this.value) throw new NotSet();
    return this.value;
  }

  /**
   * Gets value from storage.
   * @returns Promise with value from storage.
   * @throws NotSet If was not set.
   */
  public abstract get(): Promise<Type>;

  /**
   * Is storage item set?
   * @returns Promise of is storage item set?
   */
  public async isset(): Promise<boolean> {
    try {
      await this.baseGet();
      return true;
    } catch (error) {
      if (error instanceof NotSet) return false;
      else throw error;
    }
  }

  /**
   * Sets to storage.
   * @param value Set this value.
   * @returns Promise.
   */
  protected async baseSet(value: string): Promise<void> {
    this.value = value;
    return AsyncStorage.setItem(this.name, value);
  }

  /**
   * Sets to storage.
   * @param value Set this value.
   * @returns Promise.
   */
  public abstract set(value: Type): Promise<void>;

  /**
   * Removes from storage.
   * @returns Promise.
   */
  public async remove(): Promise<void> {
    this.value = null;
    return AsyncStorage.removeItem(this.name);
  }
}
