// External.
// For search element text translations.
import i18n from "i18n-js";

import NumberColumn from "./NumberColumn";

/**
 * Number column.
 */
export default class CurrencyColumn extends NumberColumn {
  /**
   * Creates an instance of Column.
   * @param name Name of the API-field.
   * @param [title] Title for column.
   * @param [has_search] Column has search (Default: true).
   */
  public constructor({
    name,
    title,
    has_search,
    precision,
  }: {
    name: string;
    title?: string;
    has_search?: boolean;
    precision?: number;
  }) {
    super({
      name,
      title,
      has_search,
      precision: precision || 2,
    });
  }

  private _format: string | undefined = "%n%u";
  private _unit: string | undefined = "€";
  private _sign_first: boolean | undefined = false;

  /**
   * Modifies and returns row value if it's set
   *
   * The toCurrency function accepts the following options:
   *
   * precision: sets the level of precision
   * separator: sets the separator between the units
   * delimiter: sets the thousands delimiter
   * format: sets the format of the output string
   * unit: sets the denomination of the currency
   * strip_insignificant_zeros: defaults to false
   * sign_first: defaults to true
   * You can provide only the options you want to override:
   *
   * I18n.toCurrency(1000, { precision: 0 }); // $1,000
   *
   * @see https://github.com/fnando/i18n-js#number-formatting
   * @param row table row
   * @returns JSX.Element | string | null element
   */
  public rowValue(row: { [key: string]: any }): JSX.Element | string | null {
    return i18n.toCurrency(row[this.name()] || 0, {
      precision: this._precision,
      separator: this._separator,
      delimiter: this._delimeter,
      format: this._format,
      unit: this._unit,
      strip_insignificant_zeros: this._strip_insignificant_zeros,
      sign_first: this._sign_first,
    });
  }
}
