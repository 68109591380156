// External imports.
// Translation.
import i18n from "i18n-js";

export abstract class HTTPError extends Error {
  /**
   * Gets status.
   */
  protected abstract get status(): number;

  /**
   * Response data.
   */
  private _data: { [key: string]: any };
  /**
   * Data value.
   * @param field Value for this field.
   * @returns Field's value or null if field not set.
   */
  private dataVal(field: string): any | null {
    if (!(field in this._data)) return null;
    return this._data[field];
  }

  /**
   * Data string value.
   * @param field Value for this field.
   * @returns Field's value or null if field not set.
   */
  public dataValStr(field: string): string | null {
    const val: any | null = this.dataVal(field);
    if (val === null) return null;
    const valType = typeof val;
    if (valType !== "string")
      throw new Error(
        "Type for field `" +
          field +
          "` was `" +
          valType +
          "` and not string: " +
          JSON.stringify(this._data)
      );
    return val;
  }

  /**
   * Data string array value.
   * @param field Value for this field.
   * @returns Field's string array value or null if field not set.
   */
  public dataValStrArr(field: string): string[] | null {
    // Get value from data.
    const val: any | null = this.dataVal(field);

    // Empty.
    if (val === null || !val) return null;

    // Check type.
    const valType = typeof val;
    if (valType !== "object")
      throw new Error(
        "Type for field `" +
          field +
          "` was `" +
          valType +
          "` and not object: " +
          JSON.stringify(this._data)
      );

    // Is not array.
    if (!Array.isArray(val))
      throw new Error(
        "Value for field `" +
          field +
          "` was not array: " +
          JSON.stringify(this._data)
      );

    // Empty array.
    if (val.length <= 0) return null;

    // Return array.
    return val;
  }

  /**
   * Response status text.
   */
  private _statusText: string;

  /**
   * Response headers.
   */
  private _headers: { [key: string]: any };

  /**
   * Response condfig.
   */
  private _config: { [key: string]: any };

  /**
   * Failed request.
   */
  private _request: XMLHttpRequest;

  /**
   * Message from data.
   */
  private dataMessage: string | null;

  /**
   * Error message.
   * @returns Error message.
   */
  public errorMessage(): string {
    return this.dataMessage || this.message;
  }

  /**
   * Creates an instance of HTTPError.
   */
  constructor(
    message: string,
    data: { [key: string]: any },
    statusText: string,
    headers: { [key: string]: any },
    config: { [key: string]: any },
    request: XMLHttpRequest
  ) {
    console.error(data);
    super(message);
    this.name = "HTTPError";
    this._data = data || {};
    this._statusText = statusText;
    this._headers = headers;
    this._config = config;
    this._request = request;
    this.dataMessage = this.dataValStr("message");
  }

  /**
   * Handle given error as HTTPError.
   * @param error Error to handle.
   * @throws Given error or HTTPError error.
   */
  public static async Handle(error: any) {
    // Is not object, so cannot be HTTPError.
    if (typeof error !== "object") throw error;

    // Does not have response data, so cannot be HTTPError.
    if (!("response" in error) || typeof error.response === "undefined")
      throw error;

    // Get message and response data from error.
    const {
      message,
      response,
    }: {
      message: string;
      response: {
        status: number;
        data: { [key: string]: any };
        headers: { [key: string]: any };
        statusText: string;
        config: { [key: string]: any };
        request: XMLHttpRequest;
      };
    } = error;

    // Throw correct HTTPError.
    switch (response.status) {
      case 400:
        if (
          response.headers["content-type"] &&
          response.headers["content-type"].indexOf("application/json") === 0 &&
          response.data instanceof Blob
        ) {
          try {
            response.data = JSON.parse(await response.data.text());
          } catch (err) {}
        }
        throw new HTTP400Error(
          message,
          response.data,
          response.statusText,
          response.headers,
          response.config,
          response.request
        );
      case 401:
        throw new HTTP401Error(
          message,
          response.data,
          response.statusText,
          response.headers,
          response.config,
          response.request
        );
      case 404:
        throw new HTTP404Error(
          message,
          response.data,
          response.statusText,
          response.headers,
          response.config,
          response.request
        );
      // No custom error for status code show throw custom.
      default:
        throw new HTTPCustomError(
          message,
          response.status,
          response.data,
          response.statusText,
          response.headers,
          response.config,
          response.request
        );
    }
  }
}

/**
 * Error for 400 HTTP error code.
 */
export class HTTP400Error extends HTTPError {
  /**
   * Gets status.
   */
  protected get status(): number {
    return 400;
  }
}

/**
 * Error for 401 HTTP error code.
 */
export class HTTP401Error extends HTTPError {
  /**
   * Gets status.
   */
  protected get status(): number {
    return 401;
  }
}

/**
 * Error for 404 HTTP error code.
 */
export class HTTP404Error extends HTTPError {
  /**
   * Gets status.
   */
  public get status(): number {
    return 404;
  }
}

/**
 * Error for custom HTTP error code.
 */
export class HTTPCustomError extends HTTPError {
  private _status: number;
  /**
   * Gets status.
   */
  protected get status(): number {
    return this._status;
  }

  /**
   * Creates an instance of HTTPCustomError.
   */
  constructor(
    message: string,
    status: number,
    data: { [key: string]: any },
    statusText: string,
    headers: { [key: string]: any },
    config: { [key: string]: any },
    request: XMLHttpRequest
  ) {
    // Inform about missing status class.
    console.error("Missing status: " + status.toString());
    // Create HTTPError.
    super(message, data, statusText, headers, config, request);
    // Set status.
    this._status = status;
  }
}

/**
 * Login error.
 */
export class LoginError extends Error {
  /**
   * HTTPError object that initialized this.
   */
  private _error: HTTPError;

  /**
   * Username error.
   */
  private _usernameError: string;
  /**
   * Gets username error.
   */
  public get usernameError(): string {
    return this._usernameError;
  }

  /**
   * Password error.
   */
  private _passwordError: string;
  /**
   * Gets password error.
   */
  public get passwordError(): string {
    return this._passwordError;
  }

  /**
   * Creates an instance of login error.
   * @param mHTTPError HTTPError to create from.
   */
  constructor(mHTTPError: HTTPError) {
    const detail: string | null = mHTTPError.dataValStr("detail");
    // Message as details or generic "Invalid username or password!".
    super(detail || i18n.t("Invalid username or password!"));
    this.name = "LoginError";

    // Set data from HTTPError.
    this._error = mHTTPError;
    this._usernameError = (
      this._error.dataValStrArr("username") || [detail || ""]
    ).join("\n");
    this._passwordError = (
      this._error.dataValStrArr("password") || [detail || ""]
    ).join("\n");
  }
}
