// Internal imports.
// Any value handeler for storage object.
import Any from "./Any";

/**
 * Search storage type.
 */
export default class Search extends Any<{ [key: string]: any }> {
  /**
   * Gets name.
   */
  protected get name(): string {
    return "search_" + super.name;
  }
}
