// External imports.
// React, React element and useState (set/get state).
import React, { ReactElement, useState } from "react";
// Translation.
import i18n from "i18n-js";
// TextInput- and View-components from React Native.
import { StyleSheet, View } from "react-native";

// Internal imports.
// REST object to handle API-calls.
import REST from "../../classes/REST";
// Messager for sending messages to user.
import Messager from "../../classes/Messager";
// Button-component.
import Button from "../Button";
// Text input -component.
import TextInput from "../TextInput";

/**
 * Login screen.
 * @param {
 *   mRest,
 *   mMessager,
 * }
 * @returns React element.
 */
const ForgotPasswordView: React.FC<{
  mRest: REST;
  mMessager: Messager;
  baseUrl: string;
  setForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  mRest,
  mMessager,
  setForgotPassword,
  baseUrl,
}: {
  mRest: REST;
  mMessager: Messager;
  setForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
  baseUrl: string;
}): ReactElement => {
  // Email.
  const [email, setEmail] = useState<{ value: string; error: string }>({
    value: "",
    error: "",
  });

  // Loading status.
  const [loading, setLoading] = useState<boolean>(false);

  const onForgotPassword = () => {
    setLoading(true);
    mRest
      .forgotPassword(email.value, baseUrl)
      .then((message: string) => {
        setLoading(false);
        mMessager.message(message);
      })
      .catch(function (error: any) {
        // Show error to user.
        mMessager.handleError(error);
        setLoading(false);
      });
  };

  return (
    <View style={styles.loginView}>
      <TextInput
        label={i18n.t("Email")}
        returnKeyType="done"
        onSubmitEditing={onForgotPassword}
        value={email.value}
        onChangeText={(text: string) => setEmail({ value: text, error: "" })}
        error={!!email.error}
        errorText={email.error}
        autoCapitalize="none"
        autoCompleteType="email"
        textContentType="emailAddress"
        disabled={loading}
      />
      <Button mode="contained" onPress={onForgotPassword} disabled={loading}>
        {i18n.t("Send login link")}
      </Button>
      <Button
        mode="contained"
        onPress={() => {
          setForgotPassword(false);
        }}
        disabled={loading}
      >
        {i18n.t("Back to login")}
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  loginView: {
    height: "100%",
    width: "100%",
    maxHeight: "20rem",
    maxWidth: "30rem",
    margin: "auto",
    padding: "1rem",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    borderRadius: "2rem",
  },
});

export default ForgotPasswordView;
