import NumberColumn from "./NumberColumn";

/**
 * Number column.
 */
export default class IntColumn extends NumberColumn {
  /**
   * Creates an instance of Column.
   * @param name Name of the API-field.
   * @param [title] Title for column.
   * @param [has_search] Column has search (Default: true).
   */
  public constructor({
    name,
    title,
    has_search,
    precision,
  }: {
    name: string;
    title?: string;
    has_search?: boolean;
    precision?: number;
  }) {
    super({
      name,
      title,
      has_search,
      precision: precision || 0,
    });
  }
}
