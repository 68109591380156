// External imports.
// React and React element.
import React, { ReactElement } from "react";
// Translation.
import i18n from "i18n-js";
// View-components from React Native.
import { ScrollView, StyleSheet } from "react-native";
import { Text } from "react-native-paper";

// Internal imports.
// REST object to handle API-calls.
import REST from "../classes/REST";
// Messager for sending messages to user.
import Messager from "../classes/Messager";
// Table-component.
import Table from "../components/Table";
// Column classes.
import IntColumn from "../classes/Columns/IntColumn";
import CurrencyColumn from "../classes/Columns/CurrencyColumn";
import DateColumn from "../classes/Columns/DateColumn";
import SelectColumn from "../classes/Columns/SelectColumn";
import IntColumnContains from "../classes/Columns/IntColumnContains";
// List type from select.
import { list } from "../components/Select";

// Show provision from envs.
import { SHOW_PROVISION } from "../consts";

/**
 * Order report screen.
 * @param {
 *   carrierNames,
 *   mRest,
 *   mMessager,
 * }
 * @returns React element.
 */
const OrderReportScreen: React.FC<{
  carrierNames: Array<list>;
  mRest: REST;
  mMessager: Messager;
}> = ({
  carrierNames,
  mRest,
  mMessager,
}: {
  carrierNames: Array<list>;
  mRest: REST;
  mMessager: Messager;
}): ReactElement => {
  // Search values.
  return (
    <ScrollView>
      <Table
        mRest={mRest}
        endpoint={"reports/order/"}
        columns={[
          new SelectColumn({
            name: "company",
            options: carrierNames,
            title: i18n.t("Company"),
            searchName: "route__vehicle__carrier__id",
          }),
          // new NumberColumn({ name: "route__vehicle__id", title: i18n.t("Car ID") }),
          new IntColumn({
            name: "route__vehicle__number",
            title: i18n.t("Car no"),
          }),
          new IntColumn({
            name: "route__id",
            title: i18n.t("Route ID"),
          }),
          new DateColumn({ name: "route__startTime", title: i18n.t("Date") }),
          new IntColumn({
            name: "id",
            title: i18n.t("Order ID"),
          }),
          new CurrencyColumn({
            name: "total_price",
            title: i18n.t("Total price"),
            has_search: false,
          }),
          new CurrencyColumn({
            name: "deductible",
            title: i18n.t("Deductible"),
            has_search: false,
          }),
          ...(SHOW_PROVISION
            ? [
                new CurrencyColumn({
                  name: "provision",
                  title: i18n.t("Provision"),
                  has_search: false,
                }),
              ]
            : []),
          new CurrencyColumn({
            name: "settlement",
            title: i18n.t("Settlement"),
            has_search: false,
          }),
          new IntColumnContains({
            name: "payment",
            title: i18n.t("Settlement number"),
          }),
        ]}
        mMessager={mMessager}
      />
      <Text style={styles.footNote}>
        {"*" +
          i18n.t("Order information includes VAT (10%)") +
          (SHOW_PROVISION
            ? "\n*" + i18n.t("Provision includes VAT (24%)")
            : "")}
        {"\n"+i18n.t("*Between 1st January 2023 and 30th April 2023 VAT is 0%")}
      </Text>
    </ScrollView>
  );
};

export default OrderReportScreen;

const styles = StyleSheet.create({
  footNote: {
    padding: 8,
    textAlign: "center",
  },
});
