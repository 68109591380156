// External imports.
// React and React element.
import React, { ReactElement } from "react";
// Style sheet keyboard- and return key type options, event classes, View-, Text- and TextInput-components from React Native.
import {
  View,
  StyleSheet,
  Text,
  KeyboardTypeOptions,
  ReturnKeyTypeOptions,
  TextInput as TextInputRN,
  NativeSyntheticEvent,
  TextInputSubmitEditingEventData,
} from "react-native";
// TextInput-component from React Native Paper.
import { TextInput as TextInputRNP } from "react-native-paper";

// Internal imports.
// App's theme.
import { theme } from "../core/theme";

/**
 *
 * @param {
 *  errorText,
 *  description,
 *  label,
 *  returnKeyType,
 *  value,
 *  onChangeText,
 *  error,
 *  autoCapitalize,
 *  autoCompleteType,
 *  textContentType,
 *  keyboardType,
 *  disabled,
 * }
 * @returns React element.
 */
const TextInput: React.FC<{
  errorText?: string | undefined;
  description?: string | undefined;
  label?: string | undefined;
  returnKeyType?: ReturnKeyTypeOptions | undefined;
  value?: string | undefined;
  onChangeText?: (((text: string) => void) & Function) | undefined;
  error?: boolean | undefined;
  autoCapitalize?: "none" | "sentences" | "words" | "characters" | undefined;
  autoCompleteType?:
    | "name"
    | "cc-csc"
    | "cc-exp"
    | "cc-exp-month"
    | "cc-exp-year"
    | "cc-number"
    | "email"
    | "password"
    | "postal-code"
    | "street-address"
    | "tel"
    | "username"
    | "off"
    | undefined;
  textContentType?:
    | "none"
    | "URL"
    | "addressCity"
    | "addressCityAndState"
    | "addressState"
    | "countryName"
    | "creditCardNumber"
    | "emailAddress"
    | "familyName"
    | "fullStreetAddress"
    | "givenName"
    | "jobTitle"
    | "location"
    | "middleName"
    | "name"
    | "namePrefix"
    | "nameSuffix"
    | "nickname"
    | "organizationName"
    | "postalCode"
    | "streetAddressLine1"
    | "streetAddressLine2"
    | "sublocality"
    | "telephoneNumber"
    | "username"
    | "password"
    | "newPassword"
    | "oneTimeCode";
  keyboardType?: KeyboardTypeOptions | undefined;
  secureTextEntry?: boolean | undefined;
  disabled?: boolean | undefined;
  inputRef?: React.Ref<TextInputRN> | undefined;
  onSubmitEditing?:
    | ((e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => void)
    | undefined;
  autoFocus?: boolean | undefined;
}> = ({
  errorText,
  description,
  label,
  returnKeyType,
  value,
  onChangeText,
  error,
  autoCapitalize,
  autoCompleteType,
  textContentType,
  keyboardType,
  secureTextEntry,
  disabled,
  inputRef,
  onSubmitEditing,
  autoFocus,
}: {
  errorText?: string | undefined;
  description?: string | undefined;
  label?: string | undefined;
  returnKeyType?: ReturnKeyTypeOptions | undefined;
  value?: string | undefined;
  onChangeText?: (((text: string) => void) & Function) | undefined;
  error?: boolean | undefined;
  autoCapitalize?: "none" | "sentences" | "words" | "characters" | undefined;
  autoCompleteType?:
    | "name"
    | "cc-csc"
    | "cc-exp"
    | "cc-exp-month"
    | "cc-exp-year"
    | "cc-number"
    | "email"
    | "password"
    | "postal-code"
    | "street-address"
    | "tel"
    | "username"
    | "off"
    | undefined;
  textContentType?:
    | "none"
    | "URL"
    | "addressCity"
    | "addressCityAndState"
    | "addressState"
    | "countryName"
    | "creditCardNumber"
    | "emailAddress"
    | "familyName"
    | "fullStreetAddress"
    | "givenName"
    | "jobTitle"
    | "location"
    | "middleName"
    | "name"
    | "namePrefix"
    | "nameSuffix"
    | "nickname"
    | "organizationName"
    | "postalCode"
    | "streetAddressLine1"
    | "streetAddressLine2"
    | "sublocality"
    | "telephoneNumber"
    | "username"
    | "password"
    | "newPassword"
    | "oneTimeCode";
  keyboardType?: KeyboardTypeOptions | undefined;
  secureTextEntry?: boolean | undefined;
  disabled?: boolean | undefined;
  inputRef?: React.Ref<TextInputRN> | undefined;
  onSubmitEditing?:
    | ((e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => void)
    | undefined;
  autoFocus?: boolean | undefined;
}): ReactElement => {
  return (
    <View style={styles.container}>
      <TextInputRNP
        style={styles.input}
        selectionColor={theme.colors.primary}
        underlineColor="transparent"
        mode="outlined"
        label={label}
        returnKeyType={returnKeyType}
        value={value}
        onChangeText={onChangeText}
        error={error}
        autoCapitalize={autoCapitalize}
        autoCompleteType={autoCompleteType}
        textContentType={textContentType}
        keyboardType={keyboardType}
        secureTextEntry={secureTextEntry}
        disabled={disabled}
        ref={inputRef}
        onSubmitEditing={onSubmitEditing}
        autoFocus={autoFocus}
      />
      {description && !errorText ? (
        <Text style={styles.description}>{description}</Text>
      ) : null}
      {errorText ? <Text style={styles.error}>{errorText}</Text> : null}
    </View>
  );
};

// Styles for this component.
const styles = StyleSheet.create({
  container: {
    width: "100%",
    marginVertical: 12,
  },
  input: {
    backgroundColor: theme.colors.surface,
  },
  description: {
    fontSize: 13,
    color: theme.colors.secondary,
    paddingTop: 8,
  },
  error: {
    fontSize: 13,
    color: theme.colors.error,
    paddingTop: 8,
  },
});

export default TextInput;
