// Internal imports.
// Storage object.
import Base from "./Base";

/**
 * Any storage type abstraction.
 * @template Type Type of the value.
 */
export default abstract class Any<Type> extends Base<Type> {
  /**
   * Gets value from storage.
   * @returns Promise with value from storage.
   * @throws NotSet If was not set.
   * @throws Error If was not of correct type.
   */
  public async get(): Promise<Type> {
    return JSON.parse(await super.baseGet());
  }

  /**
   * Sets to storage.
   * @param value Set this value.
   * @returns Promise.
   */
  public async set(value: Type): Promise<void> {
    return super.baseSet(JSON.stringify(value));
  }
}
