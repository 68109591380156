// External imports.
// Default theme from React Native Paper.
import { DefaultTheme } from "react-native-paper";
import { COLOR } from "../consts";

var c: string[] = COLOR.substring(1).split("");
if (c.length == 3) c = [c[0], c[0], c[1], c[1], c[2], c[2]];
var s: any = "0x" + c.join("");
const PRIMARY_HIGHLIGHT =
  "rgba(" + [(s >> 16) & 255, (s >> 8) & 255, s & 255].join(",") + ",0.4)";
console.debug(COLOR, PRIMARY_HIGHLIGHT);
/**
 * Theme for the app.
 */
export const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: COLOR,
    primaryHighlight: PRIMARY_HIGHLIGHT,
    secondary: "#414757",
    error: "#f13a59",
  },
};
