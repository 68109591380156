import React from "react";
import { TextInput } from "react-native-paper";
import Column from "./Column";

export default class TextColumn extends Column {
  /**
   * Name for API search.
   * @returns Name for API search.
   */
  protected searchName(): string {
    return this.name() + "__contains";
  }

  /**
   * Search element.
   * @param search Current search values.
   * @param setSearchValue Function to set search value.
   * @returns Search element.
   */
  public searchElement(
    search: { [key: string]: any },
    setSearchValue: (key: string, value: any) => void
  ): JSX.Element | null {
    if (!this.hasSearch()) return null;
    const searchName: string = this.searchName();
    return (
      <TextInput
        value={search[searchName] || ""}
        onChangeText={(text) => {
          setSearchValue(searchName, text);
        }}
      ></TextInput>
    );
  }
}
