// Sentry for errors.
import * as Sentry from "sentry-expo";

// DEBUG and SENTRY data from envs.
import { DEBUG, SENTRY_DNS, SENTRY_ENVIROMENT } from "./src/consts";

// Initialize Sentry.
Sentry.init({
  dsn: SENTRY_DNS,
  enableInExpoDevelopment: true,
  debug: DEBUG,
  environment: SENTRY_ENVIROMENT,
});

// Localization.
import i18n from "i18n-js";

// Translation strings for english.
import translationEN from "./locales/en.json";
// Translation strings for finnish.
import translationFI from "./locales/fi.json";

// Set translation strings.
i18n.translations = {
  en: translationEN,
  "en-GB": translationEN,
  "en-US": translationEN,
  fi: translationFI,
};

// Set current locale as translation locale.
import * as Localization from "expo-localization";
i18n.locale = Localization.locale;

// Allow fallbacks to other languages, if translation missing.
i18n.fallbacks = true;

// Tools.
// External.
import React, { useState, useEffect, ReactElement } from "react";
import * as Linking from "expo-linking";
// Internal.
import REST from "./src/classes/REST";
import Messager from "./src/classes/Messager";
import StorageString from "./src/classes/Storage/String";

// App theme.
import { theme } from "./src/core/theme";

// Components.
// External.
import { StyleSheet } from "react-native";
import { Provider as PaperProvider } from "react-native-paper";
import { NavigationContainer } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
const Tab = createBottomTabNavigator();

// Internal.
// Screens.
import LoginScreen from "./src/screens/LoginScreen";
import RouteReportScreen from "./src/screens/RouteReportScreen";
import OrderReportScreen from "./src/screens/OrderReportScreen";
// Components.
import Background from "./src/components/Background";
import Header from "./src/components/Header";
import Loading from "./src/components/Loading";
import PasswordScreen from "./src/screens/PasswordScreen";
import { NotSet } from "./src/classes/Storage/Base";
import { list } from "./src/components/Select";

// Init useMount.
const useMount = (func: Function) => useEffect(() => func(), []);

/**
 * App.
 * @returns React element.
 */
const App = (): ReactElement => {
  // Login state.
  const [loginState, baseSetLoginState] = useState<boolean | null>(null);

  /**
   * Set login state.
   * @param state Set to this state.
   */
  const setLoginState = (state: boolean) => {
    setCarrierNames(null);
    baseSetLoginState(state);
  };

  // Current page title.
  const [pageTitle, setPageTitle] = useState<string>("");

  // Main loading status.
  const [loading, setLoading] = useState<boolean>(false);

  // Base url for application.
  const [baseUrl, setBaseUrl] = useState<string>("");

  // Message to user data.
  const [messageData, setMessageData] = useState<{
    text: string;
    color: string;
    visible: boolean;
  }>({
    text: "",
    color: "",
    visible: false,
  });

  /**
   * Set user message.
   * @param text Message text.
   * @param color Message color
   */
  const setMessage = (
    text: string,
    color: string,
    visible: boolean = true
  ): void => {
    setMessageData({
      text: text,
      color: color,
      visible: visible,
    });
  };

  /**
   * Hide message.
   */
  const hideMessage = (): void => {
    setMessage("", "", false);
  };

  // Initalize messager object.
  const mMessager = new Messager(setMessage);

  // Error storage.
  const errorStorage: StorageString = new StorageString("error");
  errorStorage
    .get()
    .then((error: string) => {
      mMessager.error(error);
      errorStorage.remove();
    })
    .catch((error) => {
      if (!(error instanceof NotSet)) {
        mMessager.handleError(error);
      }
    });

  // Initialize REST object.
  const mRest = new REST(setLoginState);

  useMount(() => {
    const getUrlAsync = async () => {
      // Get url.
      const INITIAL_URL: string | null = await Linking.getInitialURL();
      // No url.
      if (!INITIAL_URL) return;

      // Url string to URL object.
      var url: URL = new URL(INITIAL_URL);

      // Generate and set base url.
      var tmpBaseUrl: URL = new URL(INITIAL_URL);
      for (var k in tmpBaseUrl.searchParams) tmpBaseUrl.searchParams.delete(k);
      setBaseUrl(tmpBaseUrl.toString());

      // Get token from url.
      const TOKEN_KEY = "login_token";
      const TOKEN: string | null = url.searchParams.get(TOKEN_KEY);
      // No token.
      if (!TOKEN) return;

      // Generate url without token:
      // Delete token.
      url.searchParams.delete(TOKEN_KEY);
      // Get url.
      const TO_URL: string = url.toString();

      // Try to login.
      try {
        await mRest.tokenLogin(TOKEN);
      } catch (error) {
        errorStorage.set(Messager.LogAndGetError(error));
      }

      // Open page without token.
      await Linking.openURL(TO_URL);
    };
    getUrlAsync()
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  });

  // Update title when login state changes.
  useEffect(() => {
    setPageTitle(i18n.t(loginState ? "Report" : "Login"));
  }, [loginState]);

  const [carrierNames, setCarrierNames] = useState<Array<list> | null>(null);

  // If login state is not gotten and is not loading.
  if (loginState === null && !loading) {
    // Set loading.
    setLoading(true);

    // Get login state.
    mRest
      .isLoggedIn()
      .then((isLoggedIn: boolean) => {
        setLoginState(isLoggedIn);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoginState(false);
        mRest.logout().catch((error) => {
          console.error(error);
        });
        setLoading(false);
      });
  } else if (!loading && loginState && carrierNames === null) {
    // Set loading.
    setLoading(true);
    mRest
      .carrierNames()
      .then((value: list[]) => {
        setCarrierNames(value);
        setLoading(false);
      })
      .catch((error) => {
        setCarrierNames([]);
        setLoading(false);
        mMessager.handleError(error);
      });
  }

  return (
    <PaperProvider theme={theme}>
      <Background>
        <Header
          pageTitle={pageTitle}
          mRest={mRest}
          isLoggedIn={loginState || false}
          messageData={messageData}
          hideMessage={hideMessage}
        />
        {loading ? (
          <Loading />
        ) : loginState ? (
          <NavigationContainer>
            <Tab.Navigator
              initialRouteName="Routes"
              tabBarOptions={{
                activeTintColor: "rgba(255, 255, 255, 1)",
                inactiveTintColor: "rgba(255, 255, 255, 0.7)",
                activeBackgroundColor: theme.colors.primary,
                inactiveBackgroundColor: theme.colors.primary,
                labelStyle: {
                  fontSize: "1rem",
                  fontWeight: "bold",
                },
              }}
            >
              <Tab.Screen name="Routes" options={{ title: i18n.t("Routes") }}>
                {(props) =>
                  carrierNames && (
                    <RouteReportScreen
                      carrierNames={carrierNames}
                      mRest={mRest}
                      mMessager={mMessager}
                    />
                  )
                }
              </Tab.Screen>
              <Tab.Screen name="Orders" options={{ title: i18n.t("Orders") }}>
                {(props) =>
                  carrierNames && (
                    <OrderReportScreen
                      carrierNames={carrierNames}
                      mRest={mRest}
                      mMessager={mMessager}
                    />
                  )
                }
              </Tab.Screen>
              <Tab.Screen
                name="Password"
                options={{ title: i18n.t("Password") }}
              >
                {(props) => (
                  <PasswordScreen mRest={mRest} mMessager={mMessager} />
                )}
              </Tab.Screen>
            </Tab.Navigator>
          </NavigationContainer>
        ) : (
          <LoginScreen mRest={mRest} mMessager={mMessager} baseUrl={baseUrl} />
        )}
      </Background>
    </PaperProvider>
  );
};

// Styles for this component.
const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
});

export default App;
