// Internal imports.
// String storage object class.
import String from "./String";

/**
 * Token storage type.
 */
export default class Token extends String {
  /**
   * Gets name.
   */
  protected get name(): string {
    return "token_" + super.name;
  }
}
