/* eslint-disable react-native/no-inline-styles */
import React, { memo } from "react";
import { View, StyleSheet, Text } from "react-native";
import { Checkbox } from "react-native-paper";
import type { TextStyle } from "react-native";

export interface checkbox {
  isChecked: boolean;
  label: string;
  checkboxColor?: string;
  checkboxLabelStyle?: TextStyle;
  checkboxUncheckedColor?: string;
}

const CheckboxInput = ({
  isChecked,
  label,
  checkboxColor,
  checkboxLabelStyle,
  checkboxUncheckedColor,
}: checkbox) => (
  <View style={styles.container}>
    <Checkbox.Android
      uncheckedColor={checkboxUncheckedColor || "#000007"}
      color={checkboxColor || "blue"}
      status={isChecked === true ? "checked" : "unchecked"}
    />
    <Text style={checkboxLabelStyle}> {label} </Text>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export default memo(CheckboxInput);
