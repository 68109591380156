// External imports.
// React and ReactElement.
import React, { ReactElement } from "react";
// Translation.
import i18n from "i18n-js";
// Style sheet from React Native.
import { StyleSheet } from "react-native";
// Appbar-component from React Native Paper.
import { Appbar } from "react-native-paper";

// Internal imports.
// REST object to handle API-calls.
import REST from "../classes/REST";
// App's theme.
import { theme } from "../core/theme";
// TITLE from envs.
import { TITLE } from "../consts";
// Custom Snackbar componentn.
import Snackbar from "./Snackbar";

/**
 * Header-component.
 * @param { mRest, pageTitle = "", isLoggedIn }
 * @returns React element.
 */
const Header: React.FC<{
  mRest: REST;
  pageTitle?: string;
  isLoggedIn: boolean;
  messageData: {
    text: string;
    color: string;
    visible: boolean;
  };
  hideMessage: () => void;
}> = ({
  mRest,
  pageTitle = "",
  isLoggedIn,
  messageData,
  hideMessage,
}: {
  mRest: REST;
  pageTitle?: string;
  isLoggedIn: boolean;
  messageData: {
    text: string;
    color: string;
    visible: boolean;
  };
  hideMessage: () => void;
}): ReactElement => {
  return (
    <Appbar.Header style={styles.header}>
      <Appbar.Content title={TITLE} subtitle={pageTitle} />
      <Snackbar
        message={messageData.text}
        color={messageData.color}
        visible={messageData.visible}
        hide={hideMessage}
      />
      {isLoggedIn && (
        <Appbar.Action
          icon="logout"
          accessibilityLabel={i18n.t("Logout")}
          onPress={() => mRest.logout()}
        />
      )}
    </Appbar.Header>
  );
};

// Styles for this component.
const styles = StyleSheet.create({
  header: {
    width: "100%",
    fontSize: 21,
    color: theme.colors.primary,
    fontWeight: "bold",
    paddingVertical: 12,
  },
  title: {
    textAlign: "center",
  },
  logout: {},
});

export default Header;
