// External imports.
// Axios and it's types.
import axios, { Method, AxiosInstance } from "axios";
// Localization.
import i18n from "i18n-js";
// File system.
import * as FileSystem from "expo-file-system";
// Platform.
import { Platform } from "react-native";

// Internal imports.
// API_URL from envs.
import { API_URL } from "../consts";
// Token storage class.
import Token from "./Storage/Token";
// Cached any storage.
import CacheAny from "./Storage/CacheAny";

// HTTP errors.
import {
  HTTPError,
  HTTP401Error,
  LoginError,
  HTTP404Error,
} from "./HTTPErrors";

// NotSet error.
import { NotSet } from "./Storage/Base";

// Type for select items.
import { list } from "../components/Select";

// Axios instance to API_URL.
const mAxiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
});

// Supported MIME-types.
enum MIME {
  JSON = "application/json",
  XLSX = "application/ms-excel",
  CSV = "text/csv",
}

/**
 * Response types.
 */
enum ResponseType {
  ArrayBuffer = "arraybuffer",
  Blob = "blob",
  Document = "document",
  JSON = "json",
}

/**
 * Methods
 */
enum METHOD {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete",
}

/**
 * Return type.
 */
abstract class ReturnType {
  protected abstract _MIME: MIME;
  public get ResponseType(): ResponseType {
    return this._RESPONSE_TYPE;
  }
  protected abstract _RESPONSE_TYPE: ResponseType;
  get MIME(): MIME {
    return this._MIME;
  }
}

/**
 * Return type JSON.
 */
class ReturnTypeJSON extends ReturnType {
  protected _MIME: MIME = MIME.JSON;
  protected _RESPONSE_TYPE: ResponseType = ResponseType.JSON;
}

/**
 * Return type XLSX.
 */
class ReturnTypeXLSX extends ReturnType {
  protected _MIME: MIME = MIME.XLSX;
  protected _RESPONSE_TYPE: ResponseType = ResponseType.Blob;
}

/**
 * Return type CSV.
 */
class ReturnTypeCSV extends ReturnType {
  protected _MIME: MIME = MIME.CSV;
  protected _RESPONSE_TYPE: ResponseType = ResponseType.Blob;
}

// Type for call options.
type Options = {
  method: Method;
  url: string;
  headers: { [key: string]: string };
  responseType: ResponseType;
  params: undefined | { [key: string]: any };
  data: undefined | { [key: string]: any };
};

/**
 * Rest API class.
 */
export default class REST {
  /**
   * Function to set login state with.
   */
  private _setLoginState: React.Dispatch<React.SetStateAction<boolean | null>>;

  /**
   * Creates an instance of rest.
   * @param setLoginState Function to set login state with.
   */
  public constructor(
    setLoginState: React.Dispatch<React.SetStateAction<boolean | null>>
  ) {
    this._setLoginState = setLoginState;
  }

  /**
   * Access token.
   */
  private mAccess: Token = new Token("access");

  /**
   * Refresh token.
   */
  private mRefresh: Token = new Token("refresh");

  /**
   * Determines whether is logged in.
   * @returns Promise that returns boolean that determines if is logged in.
   */
  public async isLoggedIn(): Promise<boolean> {
    return this.mAccess.isset();
  }

  /**
   * Calls REST API.
   * @param metod Call method. (GET,POST,PUT,DELETE...)
   * @param endpoint Rest endpoint to call.
   * @param pars Parameters.
   * @param [auth] Is call authenticated. (Default: true)
   * @param [counter] Counter for same call. (Default: 0)
   * @param [returnType] Return type. (Default: new ReturnTypeJSON())
   * @returns Promise with response data.
   */
  private async call(
    metod: Method,
    endpoint: string,
    pars: { [key: string]: any },
    auth: boolean = true,
    counter: number = 0,
    returnType: ReturnType = new ReturnTypeJSON()
  ): Promise<any> {
    var options: Options = await this.options(
      metod,
      endpoint,
      pars,
      auth,
      returnType
    );
    console.debug(options);
    try {
      const { data }: { data: any } = await mAxiosInstance(options);
      console.debug(data);
      return data;
    } catch (error: any) {
      try {
        await HTTPError.Handle(error);
      } catch (error: any) {
        // Is token authenticated request and no permission.
        if (auth && error instanceof HTTP401Error) {
          // First call.
          if (counter === 0) {
            // Refresh token
            await this.refreshToken();
            // and try again.
            return await this.call(
              metod,
              endpoint,
              pars,
              auth,
              counter + 1,
              returnType
            );
          } else {
            // logout.
            await this.logout();
          }
        }
        throw error;
      }
      throw error;
    }
  }

  /**
   * Download file from REST.
   * @param endpoint Endpoint.
   * @param filename Filename.
   * @param pars Parameters.
   * @param [auth] Is call authenticated. (Default: true)
   * @param [counter] Counter for same call. (Default: 0)
   * @param [returnType] Return type. (Default: new ReturnTypeJSON())
   * @returns Filepath.
   */
  private async download(
    endpoint: string,
    filename: string,
    pars: { [key: string]: any },
    auth: boolean = true,
    counter: number = 0,
    returnType: ReturnType = new ReturnTypeJSON()
  ): Promise<string> {
    if (returnType.ResponseType !== ResponseType.Blob)
      throw (
        "Given return type's response type was not " +
        ResponseType.Blob +
        " it was " +
        returnType.ResponseType +
        "!"
      );
    if (Platform.OS === "web") {
      // Download for web.

      // Get url from blob.
      const url: string = window.URL.createObjectURL(
        await this.get(endpoint, pars, auth, returnType)
      );

      // Create link.
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      // Click the link.
      link.click();

      // Return filename.
      return filename;
    } else {
      // Download for other platforms.

      // Generate url.
      const url = new URL(endpoint, API_URL);
      Object.keys(pars).forEach((key) => {
        url.searchParams.append(key, pars[key]);
      });

      try {
        // Download file from url and return download path.
        return (
          await FileSystem.downloadAsync(
            url.toString(),
            FileSystem.documentDirectory + filename,
            {
              headers: (
                await this.options(METHOD.GET, endpoint, pars, auth, returnType)
              ).headers,
            }
          )
        ).uri;
      } catch (error: any) {
        try {
          await HTTPError.Handle(error);
        } catch (error: any) {
          // Is token authenticated request and no permission.
          if (auth && error instanceof HTTP401Error) {
            // First call.
            if (counter === 0) {
              // Refresh token
              await this.refreshToken();
              // and try again.
              return await this.download(
                endpoint,
                filename,
                pars,
                auth,
                counter + 1,
                returnType
              );
            } else {
              // logout.
              await this.logout();
            }
          }
          throw error;
        }
        throw error;
      }
    }
  }

  /**
   * Options for the call.
   * @param metod Method.
   * @param endpoint Endpoint.
   * @param pars Parameters.
   * @param [auth] Is authenticated call. (Default: true)
   * @param [returnType] Return type of the call.
   * @returns options Options for the call.
   */
  private async options(
    metod: Method,
    endpoint: string,
    pars: { [key: string]: any },
    auth: boolean = true,
    returnType: ReturnType = new ReturnTypeJSON()
  ): Promise<Options> {
    var headers: { [key: string]: string } = {
      // Set translation header.
      "Accept-Language": i18n.locale,
      // Set accepted mime type.
      Accept: returnType.MIME,
    };
    // Add Authorization header.
    if (auth) {
      headers["Authorization"] = "Bearer " + (await this.mAccess.get());
    }
    // Generate options.
    const options: Options = {
      method: metod,
      url: endpoint,
      headers: headers,
      responseType: returnType.ResponseType,
      params: undefined,
      data: undefined,
    };
    if (metod === METHOD.GET) options.params = pars;
    else options.data = pars;
    return options;
  }

  /**
   * POST request to REST API.
   * @param endpoint Rest endpoint to call.
   * @param data Call with this data.
   * @param [auth] Is call authenticated. (Default: true)
   * @param [returnType] Return type. (Default: new ReturnTypeJSON())
   * @returns Promise with response data.
   */
  public async post(
    endpoint: string,
    data: object,
    auth: boolean = true,
    returnType: ReturnType = new ReturnTypeJSON()
  ): Promise<any> {
    return this.call(METHOD.POST, endpoint, data, auth, 0, returnType);
  }

  /**
   * POST request to REST API and return message.
   * @param endpoint Rest endpoint to call.
   * @param data Call with this data.
   * @param [auth] Is call authenticated. (Default: true)
   * @returns Promise with message string.
   */
  public async postMessage(
    endpoint: string,
    data: object,
    auth: boolean = true
  ): Promise<string> {
    return REST.GetFromData(
      await this.call(
        METHOD.POST,
        endpoint,
        data,
        auth,
        0,
        new ReturnTypeJSON()
      ),
      "message",
      "string",
      true
    );
  }

  /**
   * PUT request to REST API.
   * @param endpoint Rest endpoint to call.
   * @param data Call with this data.
   * @param [auth] Is call authenticated. (Default: true)
   * @param [returnType] Return type. (Default: new ReturnTypeJSON())
   * @returns Promise with response data.
   */
  public async put(
    endpoint: string,
    data: object,
    auth: boolean = true,
    returnType: ReturnType = new ReturnTypeJSON()
  ): Promise<any> {
    return this.call(METHOD.PUT, endpoint, data, auth, 0, returnType);
  }

  /**
   * GET request to REST API.
   * @param endpoint Rest endpoint to call.
   * @param data Call with this data.
   * @param [auth] Is call authenticated. (Default: true)
   * @param [returnType] Return type. (Default: new ReturnTypeJSON())
   * @returns Promise with response data.
   */
  public async get(
    endpoint: string,
    data: object,
    auth: boolean = true,
    returnType: ReturnType = new ReturnTypeJSON()
  ): Promise<any> {
    return this.call(METHOD.GET, endpoint, data, auth, 0, returnType);
  }

  /**
   * Logout from REST API.
   * @returns Promise.
   */
  public async logout(): Promise<void> {
    this._setLoginState(false);
    await this.mAccess.remove();
    await this.mRefresh.remove();
    await this.storageCarrierNames.remove();
  }

  /**
   * Get given key value from data.
   * @param data From this data.
   * @param key For this key.
   * @param type Typeof must return this.
   * @param [noEmpty] Empty not allowed. (Default: false)
   * @returns Given key value from data.
   * @throws Error If failed to get or was not asked type.
   */
  private static GetFromData(
    data: any,
    key: string,
    type: string,
    noEmpty: boolean = false
  ): any {
    // Check that given data was object.
    const dataType: string = typeof data;
    if (dataType !== "object")
      throw new Error(
        "Given data's type was `" +
          dataType +
          "` and not object:" +
          JSON.stringify(dataType)
      );

    // If key was not in data.
    if (!(key in data))
      throw new Error(
        "Key `" + key + "` not found in data: " + JSON.stringify(data)
      );

    // Get value and type.
    const tmp: any = data[key];
    const tmpType = typeof tmp;

    // Type was not same as asked.
    if (tmpType !== type)
      throw new Error(
        "Value for `" +
          key +
          "` was of type `" +
          tmpType +
          "` and not type `" +
          type +
          "`: " +
          JSON.stringify(tmp)
      );

    // Empty not wanted and value was empty.
    if (noEmpty && !tmp)
      throw new Error(
        "Value for `" +
          key +
          "` was empty and empty was not allowed: " +
          JSON.stringify(tmp)
      );

    // Return value.
    return tmp;
  }

  /**
   * Login to REST API.
   * @param token Token.
   * @returns Promise.
   */
  public async tokenLogin(token: string): Promise<void> {
    // Get tokens from REST API.
    return this.set_login(
      await this.post(
        "/user/token_login/",
        {
          token: token,
        },
        false
      )
    );
  }

  /**
   * Login to REST API.
   * @param username Username.
   * @param password Password.
   * @returns Promise.
   */
  public async login(username: string, password: string): Promise<void> {
    try {
      // Get tokens from REST API.
      await this.set_login(
        await this.post(
          "/token/",
          {
            username: username,
            password: password,
          },
          false
        )
      );
    } catch (error: any) {
      // Logout.
      await this.logout();
      if (error instanceof HTTPError) throw new LoginError(error);
      else throw error;
    }
  }

  /**
   * Set login data from given rest data.
   * @param data Tokens from rest.
   * @returns Promise.
   */
  private async set_login(data: { [key: string]: any }): Promise<void> {
    // Get access token.
    const access: string = REST.GetFromData(data, "access", "string", true);

    // Get refresh token.
    const refresh: string = REST.GetFromData(data, "refresh", "string", true);

    // Set tokens.
    await this.mAccess.set(access);
    await this.mRefresh.set(refresh);

    // Check that tokens where set.
    if (!(await this.mAccess.get())) throw new Error("mAccess empty!");
    if (!(await this.mRefresh.get())) throw new Error("mRefresh empty!");

    // Set as logged on.
    this._setLoginState(true);
  }

  /**
   * Get new access token for current refresh token.
   * @returns Promise.
   */
  async refreshToken(): Promise<void> {
    try {
      await this.mAccess.set(
        REST.GetFromData(
          await this.post(
            "/token/refresh/",
            {
              refresh: await this.mRefresh.get(),
            },
            false
          ),
          "access",
          "string",
          true
        )
      );
      this._setLoginState(true);
    } catch (err) {
      await this.logout();
      throw err;
    }
  }

  /**
   * Get from API.
   * @param endpoint Endpoint to get from.
   * @param search Search values.
   * @returns Promise with data.
   */
  public async getRest<DataType>(
    endpoint: string,
    search: { [key: string]: any }
  ): Promise<{
    next: string | null;
    previous: string | null;
    page: number;
    pages: number;
    count: number;
    results: DataType[];
  }> {
    try {
      return await this.get(endpoint, search, true);
    } catch (error) {
      // If got 404 error and page was not 1.
      if (
        error instanceof HTTP404Error &&
        "page" in search &&
        search.page != 1
      ) {
        // Set to first page and try again.
        search.page = 1;
        return this.getRest(endpoint, search);
      }
      throw error;
    }
  }

  /**
   * Get report from API.
   * @param endpoint Endpoint to get report from.
   * @param search Search values.
   * @returns Promise with report data.
   */
  async report(
    endpoint: string,
    search: { [key: string]: any }
  ): Promise<{
    next: string | null;
    previous: string | null;
    page: number;
    pages: number;
    count: number;
    results: { [key: string]: any }[];
  }> {
    return this.getRest<{ [key: string]: any }>(endpoint, search);
  }

  /**
   * Downloads XLSX-file from REST.
   * @param endpoint Endpoint.
   * @param filename Filename without ext.
   * @param [search] Search values. (Default: {})
   * @returns Filepath.
   */
  public async downloadXLSX(
    endpoint: string,
    filename: string,
    search: { [key: string]: any } = {}
  ): Promise<string> {
    return this.downloadRESTFile(
      endpoint,
      filename + ".xlsx",
      new ReturnTypeXLSX(),
      search
    );
  }

  /**
   * Downloads file from REST.
   * @param endpoint Endpoint.
   * @param filename Filename.
   * @param [returnType] Return type. (Default: new ReturnTypeJSON())
   * @param [search] Search values. (Default: {})
   * @returns Filepath.
   */
  private async downloadRESTFile(
    endpoint: string,
    filename: string,
    returnType: ReturnType,
    search: { [key: string]: any } = {}
  ): Promise<string> {
    if ("page" in search) delete search.page;
    return this.download(endpoint, filename, search, true, 0, returnType);
  }

  /**
   * Forgot password.
   * @param email Send password to this email.
   * @param baseUrl Base url for service.
   * @returns Promise with message as string.
   */
  public async forgotPassword(email: string, baseUrl: string): Promise<string> {
    return this.postMessage(
      "/user/forgot_password/",
      {
        email: email,
        baseUrl: baseUrl,
      },
      false
    );
  }

  /**
   * Set password.
   * @param password Set password to this.
   * @returns Promise with message as string.
   */
  public async setPassword(password: string): Promise<string> {
    return this.postMessage(
      "/user/set_password/",
      {
        password: password,
      },
      true
    );
  }

  private storageCarrierNames = new CacheAny<Array<list>>("carrierNames");

  /**
   * Carrier names.
   * @returns Carrier ids and names as array of list objects.
   */
  public async carrierNames(): Promise<Array<list>> {
    try {
      return await this.storageCarrierNames.getCache();
    } catch (error) {
      if (!(error instanceof NotSet)) console.error(error);
      var carriers: Array<list> = [];
      (
        await this.get(
          "carriers/",
          {
            ordering: "companyName,businessId",
          },
          true
        )
      ).forEach((carrier: any) => {
        carriers.push({
          _id: carrier.id,
          value: carrier.companyName + " (" + carrier.businessId + ")",
        });
      });
      // Cache for 1 hour.
      this.storageCarrierNames.setCache(carriers, 3600000);
      return carriers;
    }
  }
}
