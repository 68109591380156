/**
 * Column class.
 */
export default class Column {
  /**
   * Name of the API-field.
   */
  private _name: string;
  /**
   * Name of the API-field.
   * @returns Name of the API-field.
   */
  public name(): string {
    return this._name;
  }

  /**
   * Title of the column
   */
  private _title: string;
  /**
   * Column's title.
   * @returns Column's title.
   */
  public title(): string {
    return this._title;
  }

  /**
   * Column has search.
   */
  private _has_search: boolean;
  /**
   * Column has search.
   * @returns Column has search.
   */
  public hasSearch(): boolean {
    return this._has_search;
  }

  /**
   * The toNumber and toPercentage functions accept the following options:
   *
   * precision: defaults to 3
   * separator: defaults to .
   * delimiter: defaults to ,
   * strip_insignificant_zeros: defaults to false
   */
  protected _precision: number | undefined;
  protected _separator: string = ",";
  protected _delimeter: string = " ";
  protected _strip_insignificant_zeros: boolean | undefined;

  /**
   * Name for API search.
   * @returns Name for API search.
   */
  protected searchName(): string {
    return this.name();
  }

  /**
   * Creates an instance of Column.
   * @param name Name of the API-field.
   * @param [title] Title for column.
   * @param [has_search] Column has search (Default: true).
   */
  public constructor({
    name,
    title,
    has_search,
    precision,
  }: {
    name: string;
    title?: string;
    has_search?: boolean;
    precision?: number;
  }) {
    this._name = name;
    this._title = title || "";
    this._has_search = typeof has_search === "boolean" ? has_search : true;
    this._precision = precision === 0 ? precision : precision || 3;
  }

  /**
   * Search element.
   * @param search Current search values.
   * @param setSearchValue Function to set search value.
   * @returns Search element or null.
   */
  public searchElement(
    search: { [key: string]: any },
    setSearchValue: (key: string, value: any) => void
  ): JSX.Element | null {
    if (!this.hasSearch()) return null;
    return null;
  }

  /**
   * Modifies and returns row value if it's set
   * @param row table row
   * @returns JSX.Element | string | null element
   */
  public rowValue(row: { [key: string]: any }): JSX.Element | string | null {
    return row[this.name()] || null;
  }
}
