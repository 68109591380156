// External imports.
// React and React element.
import React, { ReactElement } from "react";
// React Native components.
import {
  ImageBackground,
  StyleSheet,
  KeyboardAvoidingView,
} from "react-native";

// App's theme.
import { theme } from "../core/theme";

/**
 * Background component.
 * @param {
 *   children,
 * }
 * @returns React element.
 */
const Background: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): ReactElement => {
  return (
    <ImageBackground
      source={require("../../assets/background_dot.png")}
      resizeMode="repeat"
      style={styles.background}
    >
      <KeyboardAvoidingView style={styles.container}>
        {children}
      </KeyboardAvoidingView>
    </ImageBackground>
  );
};

// Styles for this component.
const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: "100%",
    backgroundColor: theme.colors.surface,
  },
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    justifyContent: "center",
  },
});

export default Background;
