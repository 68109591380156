// External.
// For text translations.
import i18n from "i18n-js";

// Internal.
// Parent.
import DateColumn from "./DateColumn";
// Component for picking date range.

/**
 * Time column, extends DateColumn.
 */
export default class TimeColumn extends DateColumn {
  // https://github.com/fnando/i18n-js#date-formatting
  TIME_FORMAT = "%-H:%M";
  /**
   * Modifies and returns row value if it's set
   * @param row table row
   * @returns JSX.Element | string | null element
   */
  public rowValue(row: { [key: string]: any }): JSX.Element | string | null {
    const value = row[this.name()] || null;
    if (value) return i18n.strftime(new Date(value), this.TIME_FORMAT);

    return null;
  }
}
