// External imports.
// React and React element.
import React, { ReactElement } from "react";
// Translation.
import i18n from "i18n-js";
// View-components from React Native.
import { ScrollView, StyleSheet } from "react-native";
import { Text } from "react-native-paper";

// Internal imports.
// REST object to handle API-calls.
import REST from "../classes/REST";
// Messager for sending messages to user.
import Messager from "../classes/Messager";
// App theme.
import { theme } from "../core/theme";
// Table-component.
import Table from "../components/Table";
// Column classes.
import CurrencyColumn from "../classes/Columns/CurrencyColumn";
import IntColumn from "../classes/Columns/IntColumn";
import DateColumn from "../classes/Columns/DateColumn";
import TextColumn from "../classes/Columns/TextColumn";
import TimeColumn from "../classes/Columns/TimeColumn";
import MeterAsKMColumn from "../classes/Columns/MeterAsKMColumn";
import SelectColumn from "../classes/Columns/SelectColumn";
import IntColumnContains from "../classes/Columns/IntColumnContains";
import { list } from "../components/Select";

/**
 * Route report screen.
 * @param {
 *   carrierNames,
 *   mRest,
 *   mMessager,
 * }
 * @returns React element.
 */
const RouteReportScreen: React.FC<{
  carrierNames: Array<list>;
  mRest: REST;
  mMessager: Messager;
}> = ({
  carrierNames,
  mRest,
  mMessager,
}: {
  carrierNames: Array<list>;
  mRest: REST;
  mMessager: Messager;
}): ReactElement => {
  // Search values.
  return (
    <ScrollView>
      <Table
        mRest={mRest}
        endpoint={"reports/route/"}
        getRowStyle={(row) => {
          const { route__changed } = row;
          if (route__changed)
            return {
              backgroundColor: theme.colors.primaryHighlight,
            };
          return null;
        }}
        columns={[
          new SelectColumn({
            name: "company",
            options: carrierNames,
            title: i18n.t("Company"),
            searchName: "route__vehicle__carrier__id",
          }),
          // new NumberColumn({ name: "vehicle__id", title: i18n.t("Car ID")}),
          new IntColumn({
            name: "route__vehicle__number",
            title: i18n.t("Car no"),
          }),
          // new TextColumn({ name: "driver__vinkaId", title: i18n.t("Driver ID")}),
          new TextColumn({
            name: "route__driver__name",
            title: i18n.t("Driver Name"),
          }),
          new IntColumn({
            name: "id",
            title: i18n.t("Order ID"),
          }),
          new DateColumn({ name: "route__startTime", title: i18n.t("Date") }),
          new TimeColumn({
            name: "route__startTime",
            title: i18n.t("Departure time"),
            has_search: false,
          }),
          new TimeColumn({
            name: "route__endTime",
            title: i18n.t("End time"),
            has_search: false,
          }),
          new MeterAsKMColumn({
            name: "distance",
            title: i18n.t("Length (m)"),
          }),
          new CurrencyColumn({
            name: "base_price",
            title: i18n.t("Base price"),
            has_search: false,
          }),
          new CurrencyColumn({
            name: "fee",
            title: i18n.t("Fee"),
            has_search: false,
          }),
          new CurrencyColumn({
            name: "total_price",
            title: i18n.t("Total price"),
            has_search: false,
          }),
          new CurrencyColumn({
            name: "deductible",
            title: i18n.t("Deductible"),
            has_search: false,
          }),
          new CurrencyColumn({
            name: "settlement",
            title: i18n.t("Settlement"),
            has_search: false,
          }),
          new TextColumn({ name: "status", title: i18n.t("Status") }),
          // TODO: Laskutuspäivä
          new IntColumnContains({
            name: "payment",
            title: i18n.t("Settlement number"),
          }),
        ]}
        mMessager={mMessager}
      />
      <Text style={styles.footNote}>
        {"*"+i18n.t("Order information includes VAT (10%)")}
        {"\n*"+i18n.t("Between 1st January 2023 and 30th April 2023 VAT is 0%")}
      </Text>
    </ScrollView>
  );
};

export default RouteReportScreen;

const styles = StyleSheet.create({
  footNote: {
    padding: 8,
    textAlign: "center",
  },
});
