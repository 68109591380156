// Internal imports.
// Storage object.
import Any from "./Any";
import { NotSet } from "./Base";

/**
 * Any storage type abstraction.
 * @template Type Type of the value.
 */
export default class CacheAny<Type> extends Any<{
  time: number;
  value: Type;
}> {
  /**
   * Gets value from storage.
   * @returns Promise with value from storage.
   * @throws NotSet If was not set.
   * @throws Error If was not of correct type.
   */
  public async getCache(): Promise<Type> {
    const data = await this.get();
    if (data.time < this.time()) {
      this.remove();
      throw new NotSet();
    }
    return data.value;
  }

  /**
   * Milliseconds since epoch.
   * @returns Milliseconds since epoch.
   */
  private time(): number {
    return new Date().getTime();
  }

  /**
   * Sets to storage.
   * @param value Set this value.
   * @returns Promise.
   */
  public async setCache(value: Type, milliseconds: number): Promise<void> {
    return this.set({ time: this.time() + milliseconds, value: value });
  }
}
