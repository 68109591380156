// External imports.
// React, React element, useState (set/get state) and useEffect (detect state changes).
import React, { ReactElement } from "react";
// Snackbar-component from React Native Paper.
import { Snackbar as BaseSnackbar } from "react-native-paper";

// Internal imports.
// App's theme.
import { theme } from "../core/theme";

/**
 * Snackbar for showing messages.
 * @param { message, color }
 * @returns React element.
 */
const Snackbar: React.FC<{
  message: string;
  color: string;
  visible: boolean;
  hide: () => void;
}> = ({
  message,
  color,
  visible,
  hide,
}: {
  message: string;
  color: string;
  visible: boolean;
  hide: () => void;
}): ReactElement => {
  return (
    <BaseSnackbar
      visible={visible}
      style={{
        width: "70%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      theme={{
        ...theme,
        colors: {
          ...theme,
          onSurface: color,
        },
      }}
      duration={3500}
      onDismiss={hide}
      action={{
        label: "X",
        onPress: () => {
          // Do something
        },
      }}
    >
      {message}
    </BaseSnackbar>
  );
};

export default Snackbar;
