import {
  API_URL as ENV_API_URL,
  SHOW_PROVISION as ENV_SHOW_PROVISION,
  TITLE as ENV_TITLE,
  SLUG as ENV_SLUG,
  ASSETS_DIR_NAME as ENV_ASSETS_DIR_NAME,
  DEBUG as ENV_DEBUG,
  SENTRY_DNS as ENV_SENTRY_DNS,
  SENTRY_ENVIROMENT as ENV_SENTRY_ENVIROMENT,
  COLOR as ENV_COLOR,
} from "react-native-dotenv";

/**
 * Transform env to boolean.
 * @param env Env value to transform to boolean.
 * @returns Given env as boolean.
 */
function bool_env(env: any): boolean {
  return typeof env === "boolean" ? env : env === "true";
}

export const API_URL: string = ENV_API_URL;
export const SHOW_PROVISION: boolean = bool_env(ENV_SHOW_PROVISION);
export const TITLE: string = ENV_TITLE;
export const SLUG: string = ENV_SLUG;
export const ASSETS_DIR_NAME: string = ENV_ASSETS_DIR_NAME;
export const DEBUG: boolean = bool_env(ENV_DEBUG);
export const SENTRY_DNS: string = ENV_SENTRY_DNS;
export const SENTRY_ENVIROMENT: string = ENV_SENTRY_ENVIROMENT;
export const COLOR: string = ENV_COLOR;
