// External imports.
// React and ReactElement.
import React, { ReactElement } from "react";
// Style sheet from React Native.
import { StyleSheet } from "react-native";
// ActivityIndicator-component from React Native Paper.
import { ActivityIndicator } from "react-native-paper";

// Internal imports.
// App's theme.
import { theme } from "../core/theme";
/**
 * Loading indicator.
 * @param {}
 * @returns React element.
 */
const Loading: React.FC<{}> = ({}: {}): ReactElement => {
  return (
    <ActivityIndicator
      style={styles.loading}
      animating={true}
      color={theme.colors.primary}
      size="large"
    />
  );
};

// Styles for this component.
const styles = StyleSheet.create({
  loading: {
    width: "100%",
    height: "100%",
  },
});

export default Loading;
