// External imports.
// React, createRef, React element and useState (set/get state).
import React, { createRef, ReactElement, useState } from "react";
// Translation.
import i18n from "i18n-js";
// TextInput- and View-components from React Native.
import { StyleSheet, TextInput as TextInputRN, View } from "react-native";

// Internal imports.
// REST object to handle API-calls.
import REST from "../classes/REST";
// Messager for sending messages to user.
import Messager from "../classes/Messager";
// Button-component.
import Button from "../components/Button";
// Text input -component.
import TextInput from "../components/TextInput";

/**
 * Password screen.
 * @param {
 *   mRest,
 *   mMessager,
 * }
 * @returns React element.
 */
const PasswordScreen: React.FC<{
  mRest: REST;
  mMessager: Messager;
}> = ({
  mRest,
  mMessager,
}: {
  mRest: REST;
  mMessager: Messager;
}): ReactElement => {
  const INITIAL_STATE = {
    value: "",
    error: "",
  };
  // Password.
  const [password, setPassword] =
    useState<{ value: string; error: string }>(INITIAL_STATE);
  const [password2, setPassword2] =
    useState<{ value: string; error: string }>(INITIAL_STATE);

  // Loading status.
  const [loading, setLoading] = useState<boolean>(false);

  // Reference to password button.
  const password2TextInput = createRef<TextInputRN>();
  /**
   * Handle login.
   */
  const onSetPassword = () => {
    try {
      setLoading(true);

      // Get and check first password.
      const p1 = password.value;
      const err_empty = i18n.t("Give new password!");
      if (!p1) {
        setPassword((password: { value: string; error: string }) => ({
          ...password,
          error: err_empty,
        }));
        throw err_empty;
      }

      // Get and check second password.
      const p2 = password2.value;
      if (!p2) {
        setPassword2((password2: { value: string; error: string }) => ({
          ...password2,
          error: err_empty,
        }));
        throw err_empty;
      }

      // Check that passwords matches.
      if (p2 !== p1) {
        const err = i18n.t("Passwords do not match!");
        setPassword2((password2: { value: string; error: string }) => ({
          ...password2,
          error: err,
        }));
        throw err;
      }

      // Set password.
      mRest
        .setPassword(p1)
        .then((msg: string) => {
          setPassword(INITIAL_STATE);
          setPassword2(INITIAL_STATE);
          setLoading(false);
          mMessager.message(msg);
        })
        .catch(function (error: any) {
          setLoading(false);
          // Show error to user.
          mMessager.handleError(error);
        });
    } catch (err) {
      setLoading(false);
      // Show error to user.
      mMessager.handleError(err);
    }
  };

  // Search values.
  return (
    <View style={styles.background}>
      <View style={styles.loginView}>
        <TextInput
          label={i18n.t("New password")}
          returnKeyType="next"
          onSubmitEditing={() => {
            if (password2TextInput.current) password2TextInput.current.focus();
          }}
          value={password.value}
          onChangeText={(text: string) =>
            setPassword({ value: text, error: "" })
          }
          error={!!password.error}
          errorText={password.error}
          secureTextEntry
          disabled={loading}
        />
        <TextInput
          label={i18n.t("New password again")}
          returnKeyType="done"
          onSubmitEditing={onSetPassword}
          value={password2.value}
          onChangeText={(text: string) =>
            setPassword2({ value: text, error: "" })
          }
          error={!!password2.error}
          errorText={password2.error}
          secureTextEntry
          disabled={loading}
          inputRef={password2TextInput}
        />
        <Button mode="contained" onPress={onSetPassword} disabled={loading}>
          {i18n.t("Set")}
        </Button>
      </View>
    </View>
  );
};

export default PasswordScreen;

const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: "100%",
  },
  loginView: {
    height: "100%",
    width: "100%",
    maxHeight: "20rem",
    maxWidth: "30rem",
    margin: "auto",
    padding: "1rem",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    borderRadius: "2rem",
  },
});
