import IntColumn from "./IntColumn";

/**
 * Number column.
 */
export default class IntColumnContains extends IntColumn {
  /**
   * Name for API search.
   * @returns Name for API search.
   */
  protected searchName(): string {
    return this.name() + "__contains";
  }
}
