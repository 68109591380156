// External imports.
// Translation.
import i18n from "i18n-js";
import { HTTPError } from "./HTTPErrors";

/**
 * Messager for sending messages to user.
 */
export default class Messager {
  /**
   * Function to set message to user.
   */
  private __setMessage: (message: string, color: string) => void;

  /**
   * Creates an instance of Messager.
   * @param setMessage Function to set message to user.
   */
  public constructor(setMessage: (message: string, color: string) => void) {
    this.__setMessage = setMessage;
  }

  /**
   * Handle error.
   */
  public handleError = (error: any): void => {
    this.error(Messager.LogAndGetError(error));
  };

  /**
   * Log and get error message.
   * @param error Error to handle.
   * @returns Error message.
   */
  public static LogAndGetError = (error: any): string => {
    var msg: string = "";
    console.error(error);
    if (typeof error === "string") msg = error;
    else if (error instanceof HTTPError) msg = error.errorMessage();
    else if (error instanceof Error) msg = error.message;
    else msg = i18n.t("Unknown error!");
    return msg;
  };

  /**
   * Send error to user.
   */
  public error = (error: string): void => {
    this.__setMessage(error, "red");
  };

  /**
   * Send message to user.
   */
  public message = (message: string): void => {
    this.__setMessage(message, "green");
  };
}
