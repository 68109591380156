// External.
// For search element.
import React from "react";
// For text translations.
import i18n from "i18n-js";
import { TextInput } from "react-native-paper";

import Column from "./Column";

/**
 * Number column.
 */
export default class NumberColumn extends Column {
  /**
   * Search element.
   * @param search Current search values.
   * @param setSearchValue Function to set search value.
   * @returns Search element.
   */
  public searchElement(
    search: { [key: string]: any },
    setSearchValue: (key: string, value: any) => void
  ): JSX.Element | null {
    if (!this.hasSearch()) return null;
    const searchName: string = this.searchName();
    return (
      <TextInput
        value={search[searchName] || ""}
        onChangeText={(text) => {
          setSearchValue(searchName, text);
        }}
      ></TextInput>
    );
  }

  /**
   * Modifies and returns row value if it's set
   * @param row table row
   * @returns JSX.Element | string | null element
   */
  public rowValue(row: { [key: string]: any }): JSX.Element | string | null {
    const value = row[this.name()] || null;
    if (value)
      return i18n.toNumber(value, {
        delimiter: this._delimeter,
        separator: this._separator,
        precision: this._precision,
        strip_insignificant_zeros: this._strip_insignificant_zeros,
      });

    return null;
  }
}
