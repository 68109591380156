// External imports.
// React and React element.
import React, { ReactElement, useEffect } from "react";
// Translation.
import i18n from "i18n-js";
// Button-component from React Native Paper.
import { Button } from "react-native-paper";
// Date picker-component from React Native Paper Dates.
import { DatePickerModal } from "react-native-paper-dates";

/**
 * Date range picker-component.
 * @param {
 *   onSelected,
 *   buttonText,
 *   startDate = undefined,
 *   endDate = undefined,
 * }
 */
const DateRangePicker: React.FC<{
  onSelected: Function;
  buttonText: string;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
}> = ({
  onSelected,
  buttonText,
  startDate = undefined,
  endDate = undefined,
}: {
  onSelected: Function;
  buttonText: string;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
}): ReactElement => {
  // Store range start and end dates.
  const [range, setRange] = React.useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
  }>({ startDate: startDate, endDate: endDate });

  // Is selection open.
  const [open, setOpen] = React.useState(false);

  // On selection dismissed.
  const onDismiss = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  // Range confirmed.
  const onConfirm = React.useCallback(
    ({ startDate, endDate }) => {
      setOpen(false);
      setRange({ startDate, endDate });
      onSelected(startDate, endDate);
    },
    [setOpen, setRange]
  );

  // Set range to given end and start dates.
  useEffect(() => {
    setRange({
      startDate: startDate,
      endDate: endDate,
    });
  }, [startDate, endDate]);
  return (
    <>
      <Button onPress={() => setOpen(true)} uppercase={false} mode="outlined">
        {range.startDate || range.endDate
          ? (range.startDate ? range.startDate.toLocaleDateString() : "") +
            "-" +
            (range.endDate ? range.endDate.toLocaleDateString() : "")
          : buttonText}
      </Button>
      <DatePickerModal
        // locale={'en'} optional, default: automatic
        mode="range"
        visible={open}
        onDismiss={onDismiss}
        startDate={range.startDate}
        endDate={range.endDate}
        onConfirm={onConfirm}
        validRange={{
          startDate: new Date(2021, 0, 1),
          endDate: new Date(),
        }}
        // onChange={} // same props as onConfirm but triggered without confirmed by user
        // locale={'nl'} // optional
        saveLabel={i18n.t("Select")}
        label={i18n.t("Select period")}
        startLabel={i18n.t("From")}
        endLabel={i18n.t("To")}
        // animationType="slide" // optional, default is slide on ios/android and none on web
      />
    </>
  );
};

export default DateRangePicker;
