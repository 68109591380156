import React from "react";
import PaperSelect, { list } from "../../components/Select";
import Column from "./Column";
/**
 * Select column.
 */
export default class SelectColumn extends Column {
  /**
   * Options.
   */
  private _options: Array<list> = [];

  /**
   * Search name.
   */
  private _searchName: string;

  /**
   * Name for API search.
   * @returns Name for API search.
   */
  protected searchName(): string {
    return this._searchName;
  }

  /**
   * Creates an instance of select column.
   * @param {
   *     name,
   *     options,
   *     title,
   *     has_search,
   *     searchName,
   *   }
   */
  public constructor({
    name,
    options,
    title,
    has_search,
    searchName,
  }: {
    name: string;
    options: Array<list>;
    title?: string;
    has_search?: boolean;
    searchName?: string;
  }) {
    super({
      name,
      title,
      has_search,
    });
    this._searchName = searchName ?? this.name();
    this._options = options;
  }

  /**
   * Search element.
   * @param search Current search values.
   * @param setSearchValue Function to set search value.
   * @returns Search element.
   */
  public searchElement(
    search: { [key: string]: any },
    setSearchValue: (key: string, value: any) => void
  ): JSX.Element | null {
    if (!this.hasSearch()) return null;
    const searchName: string = this.searchName();
    const val = search[searchName] || null;
    var selectedArrayList: Array<list> = [];
    var value = val;
    this._options.some((option: list) => {
      if (option._id === val) {
        selectedArrayList = [option];
        value = option.value;
        return true;
      }
      return false;
    });
    return (
      <PaperSelect
        value={value || ""}
        arrayList={this._options}
        setSelectedList={(selectedList: Array<list>) => {
          setSearchValue(
            searchName,
            selectedList.length === 1 ? selectedList[0]._id : null
          );
        }}
        multiEnable={false}
        selectedList={selectedArrayList}
      ></PaperSelect>
    );
  }
}
