// External imports.
// React, createRef, React element and useState (set/get state).
import React, { createRef, ReactElement, useState } from "react";
// Translation.
import i18n from "i18n-js";
// TextInput- and View-components from React Native.
import { StyleSheet, TextInput as TextInputRN, View } from "react-native";

// Internal imports.
// REST object to handle API-calls.
import REST from "../../classes/REST";
// Messager for sending messages to user.
import Messager from "../../classes/Messager";
// Button-component.
import Button from "../Button";
// Text input -component.
import TextInput from "../TextInput";
import { LoginError } from "../../classes/HTTPErrors";

/**
 * Login screen.
 * @param {
 *   mRest,
 *   mMessager,
 * }
 * @returns React element.
 */
const LoginView: React.FC<{
  mRest: REST;
  mMessager: Messager;
  setForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  mRest,
  mMessager,
  setForgotPassword,
}: {
  mRest: REST;
  mMessager: Messager;
  setForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement => {
  // Username.
  const [username, setUsername] = useState<{ value: string; error: string }>({
    value: "",
    error: "",
  });

  // Password.
  const [password, setPassword] = useState<{ value: string; error: string }>({
    value: "",
    error: "",
  });

  // Loading status.
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * Handle login.
   */
  const onLogin = () => {
    setLoading(true);
    mRest
      .login(username.value, password.value)
      .then(() => {
        setLoading(false);
        mMessager.message(i18n.t("Logged in!"));
      })
      .catch(function (error: any) {
        // Login error.
        if (error instanceof LoginError) {
          // Set username error.
          setUsername((username: { value: string; error: string }) => ({
            ...username,
            error: error.usernameError,
          }));

          // Set password error.
          setPassword((password: { value: string; error: string }) => ({
            ...password,
            error: error.passwordError,
          }));

          // Show error to user.
          mMessager.handleError(error);
        }
        // Show error to user.
        mMessager.handleError(error);
        setLoading(false);
      });
  };

  // Reference to password button.
  const passwordButton = createRef<TextInputRN>();

  return (
    <View style={styles.loginView}>
      <TextInput
        label={i18n.t("Username")}
        returnKeyType="next"
        onSubmitEditing={() => {
          if (passwordButton.current) passwordButton.current.focus();
        }}
        value={username.value}
        onChangeText={(text: string) => setUsername({ value: text, error: "" })}
        error={!!username.error}
        errorText={username.error}
        autoCapitalize="none"
        autoCompleteType="username"
        textContentType="username"
        keyboardType="default"
        disabled={loading}
        autoFocus={true}
      />
      <TextInput
        label={i18n.t("Password")}
        returnKeyType="done"
        onSubmitEditing={onLogin}
        value={password.value}
        onChangeText={(text: string) => setPassword({ value: text, error: "" })}
        error={!!password.error}
        errorText={password.error}
        secureTextEntry
        disabled={loading}
        inputRef={passwordButton}
      />
      <Button mode="contained" onPress={onLogin} disabled={loading}>
        {i18n.t("Login")}
      </Button>
      <Button
        mode="contained"
        onPress={() => {
          setForgotPassword(true);
        }}
        disabled={loading}
      >
        {i18n.t("Forgot password")}
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  loginView: {
    height: "100%",
    width: "100%",
    maxHeight: "20rem",
    maxWidth: "30rem",
    margin: "auto",
    padding: "1rem",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    borderRadius: "2rem",
  },
});

export default LoginView;
