// External imports.
// React and React element.
import React, { ReactElement } from "react";
// Style sheet from React Native.
import { StyleSheet } from "react-native";
// Button-component from React Native Paper.
import { Button as PaperButton } from "react-native-paper";

// Internal.
// App's theme.
import { theme } from "../core/theme";

/**
 * Button component.
 * @param { onPress, mode, children, disabled }
 * @returns React element.
 */
const Button: React.FC<{
  onPress?: (() => void) | undefined;
  mode?: "text" | "outlined" | "contained" | undefined;
  children: React.ReactNode;
  disabled?: boolean | undefined;
}> = ({
  onPress,
  mode,
  children,
  disabled,
}: {
  onPress?: (() => void) | undefined;
  mode?: "text" | "outlined" | "contained" | undefined;
  children: React.ReactNode;
  disabled?: boolean | undefined;
}): ReactElement => {
  return (
    <PaperButton
      style={[
        styles.button,
        mode === "outlined" && { backgroundColor: theme.colors.surface },
      ]}
      labelStyle={styles.text}
      mode={mode}
      onPress={onPress}
      children={children}
      disabled={disabled}
    />
  );
};

// Styles for this component.
const styles = StyleSheet.create({
  button: {
    width: "100%",
    marginVertical: 10,
    paddingVertical: 2,
  },
  text: {
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 26,
  },
});

export default Button;
